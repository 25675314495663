







































import { PropType } from 'vue'
import ToolTip from './ToolTip.vue'
import TextInput from './TextInput.vue'
import { InputOptions } from '@typesCustom/index'

export default TextInput.extend({
  components: {
    ToolTip,
  },
  props: {
    options: {
      type: Object as PropType<InputOptions>,
      required: false,
    },
  },
  data() {
    return {
      value: null as number | null | '',
    }
  },
  methods: {
    saveAnswer() {
      const { min, max } = this.options
      if (this.value === null || this.value === '' || (min && this.value < min) || (max && this.value > max)) {
        this.$store.dispatch('deleteAnswer', { id: this.id, value: null })
        return
      }
      this.$store.dispatch('saveAnswer', { id: this.id, value: Number(this.value) })
    },
    startTimer() {
      setTimeout(() => {
        this.saveAnswer()
      }, 2000)
    },
  },
})
